import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useAppContext } from "../context/store";
import ServiceCard from "./ServiceCard";

const ServiceCardMap = () => {
  const { data } = useAppContext();
  const serviceAnimate = {
    hidden: { opacity: 0, x: 300 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };

  const serviceData =
    data?.filter((item) => item?.name === "services")[0]?.serviceItems || [];
  return (
    <motion.div
      className="services-wrap gap-24 pt-24"
      transition={{ staggerChildren: 0.5 }}
      initial="hidden"
      whileInView={"visible"}
      viewport={{ once: false, amount: 0.2 }}
    >
      {serviceData.map((item, index) => {
        return (
          <motion.div variants={serviceAnimate} key={index}>
            <ServiceCard
              key={index}
              id={item.id}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default ServiceCardMap;
