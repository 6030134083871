import React from "react";
import { motion } from "framer-motion";
import HeroHexagon from "../HeroHexagon";
import ServiceCardMap from "../ServiceCardMap";

const Services = () => {
  const textAnimate = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <>
      <div className="hero is-flex is-center  dir-column ">
        <div className="container">
          <div className="hero-content clr-white">
            <motion.div
              className="content"
              initial="hidden"
              whileInView={"visible"}
              viewport={{ once: false, amount: 0.1 }}
              transition={{ staggerChildren: 1 }}
            >
              <motion.h5 className="fw-600 " variants={textAnimate}>
                <span className="line-shape pb-12 line-shape_left">
                  What we do
                </span>
              </motion.h5>

              <motion.h1 className="my-32 fs-h3" variants={textAnimate}>
                we work on <span className="italic">ideas</span> that can
                provide {""}
                <span className="italic">simple solutions</span>
                {""} to {""}
                <span className="italic">complex</span> problems.
              </motion.h1>
              <motion.p className="fw-400" variants={textAnimate}>
                Smooth <span className="italic"> Business</span> starts with
                good service {""}
                <span className="italic"> consultation</span>.
              </motion.p>
            </motion.div>
            <HeroHexagon />
          </div>
        </div>
      </div>
      <section className="services services-page">
        <div className="container">
          <div className="title  mb-40">
            <div className="title-content ">
              <h5 className="clr-secondary">
                <span className="line-shape line-shape_left">services</span>
              </h5>
            </div>
          </div>
          <ServiceCardMap />
        </div>
      </section>
    </>
  );
};

export default Services;
