import React from "react";
import { motion } from "framer-motion";
import sqlIcon from "../assets/images/mysql.svg";
import reactIcon from "../assets/images/react.svg";
import railsIcon from "../assets/images/rails.svg";
import gatsbyIcon from "../assets/images/gatsby.svg";
import appleIcon from "../assets/images/apple.svg";
// import bannerImage from "../assets/images/banner.jpg";
import bannerImage from "../assets/images/portfolio.jpg";

const HeroHexagon = () => {
  const hexagonAnimate = {
    hidden: { rotate: 0, opacity: 0 },
    visible: {
      rotate: [0, 0, 270, 270, 0],
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 2,
        staggerDirection: -1,
      },
    },
  };
  return (
    <div className="hero-figure">
      <motion.div
        className="circle"
        initial="hidden"
        whileInView={"visible"}
        viewport={{ once: false, amount: 0.1 }}
        transition={{ staggerChildren: 0.2 }}
      >
        <img className="hero-image" src={bannerImage} alt="" />
        <motion.div className="hero-svg top-left" variants={hexagonAnimate}>
          <svg
            className="hexagon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.4 197.4"
          >
            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z"></path>
          </svg>
          <img src={railsIcon} alt="node" />
        </motion.div>
        <motion.div className="hero-svg top-right" variants={hexagonAnimate}>
          <svg
            className="hexagon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.4 197.4"
          >
            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z"></path>
          </svg>
          <img src={reactIcon} alt="react" />
        </motion.div>
        <motion.div className="hero-svg bottom-left" variants={hexagonAnimate}>
          <svg
            className="hexagon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.4 197.4"
          >
            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z"></path>
          </svg>
          <img src={sqlIcon} alt="rails" />
        </motion.div>
        <motion.div className="hero-svg bottom-right" variants={hexagonAnimate}>
          <svg
            className="hexagon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.4 197.4"
          >
            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z"></path>
          </svg>
          <img src={gatsbyIcon} alt="node" />
        </motion.div>
        <motion.div className="hero-svg bottom" variants={hexagonAnimate}>
          <svg
            className="hexagon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.4 197.4"
          >
            <path d="M0,58.4v79.9c0,6.5,3.5,12.6,9.2,15.8l70.5,40.2c5.6,3.2,12.4,3.2,18,0l70.5-40.2c5.7-3.2,9.2-9.3,9.2-15.8V58.4 c0-6.5-3.5-12.6-9.2-15.8L97.7,2.4c-5.6-3.2-12.4-3.2-18,0L9.2,42.5C3.5,45.8,0,51.8,0,58.4z"></path>
          </svg>
          <img src={appleIcon} alt="node" />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HeroHexagon;
