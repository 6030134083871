import React, { useEffect } from "react";
import { FaHeadphonesAlt, FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Link } from "react-router-dom";
import PortfolioItemMap from "../PortfolioItemMap";
import ServiceCardMap from "../ServiceCardMap";
import { useAppContext } from "../../context/store";
import HeroHexagon from "../HeroHexagon";

const Home = () => {
  const { data, setActive } = useAppContext();

  const textAnimate = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const TrustedData =
    data?.filter((item) => item?.name === "trusted")[0]?.trustedItems || [];
  return (
    <>
      <div className="hero is-flex is-center dir-column ">
        <div className="container">
          <div className="hero-content clr-white">
            <motion.div
              className="content"
              initial="hidden"
              whileInView={"visible"}
              viewport={{ once: false, amount: 0.1 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.h5 className="fw-600 " variants={textAnimate}>
                We specialize in the field of software and web development.
              </motion.h5>

              <motion.h1 className="mt-36 mb-40 fs-h3" variants={textAnimate}>
                Transform your Business with{" "}
                <span className="italic">Cutting-Edge</span> Technology
                <span className="line-shape italic"> Solutions</span>.
              </motion.h1>
              <motion.div className="buttons" variants={textAnimate}>
                <Link
                  to="./contact"
                  className="btn btn-secondary  is-flex is-align-center col-gap-8 fw-500"
                >
                  <FaHeadphonesAlt />
                  <span>Let's Talk</span>
                </Link>
              </motion.div>
            </motion.div>
            <HeroHexagon />
          </div>
        </div>
      </div>
      <section className="about">
        <div className="container">
          <div className="title text-center mb-40">
            <div className="title-content title-content_max">
              <h5 className="clr-secondary ">
                <span className="line-shape">About us</span>
              </h5>
              <h2>
                We have worked with various <span className="clr-primary"> national and international </span> clients.
              </h2>
            </div>
          </div>
          <div className="about-content text-center">
            <p className="mb-32">
              The company was established in 2019 with a mission to address
              contemporary problems in developing countries through
              technological innovations, particularly in digital and spatial
              technology.
            </p>
            <p>
              Since establishment, we have build technical products and services which can be used by individuals, companies
              or organizations not only in local industries but also globally to better their day-to-day undertakings.
              We have more than four years of experience in developing technological solutions and delivering
              high-quality services.
            </p>
          </div>
        </div>
      </section>
      <section className="services clr-bg">
        <div className="container">
          <div className="title  mb-40">
            <div className="title-content ">
              <h5 className="clr-secondary">
                <span className="line-shape line-shape_left">services</span>
              </h5>
              <h2>
                we work on <span className="clr-primary">ideas </span>
                that can provide simple
                <span className="clr-primary"> solutions</span> to
                <span className="clr-primary"> complex problems</span>.
              </h2>
            </div>
          </div>
          <ServiceCardMap />
        </div>
      </section>
      <section className="highlight">
        <div className="container">
          <div className="title  mb-40">
            <div className="title-content ">
              <h5 className="clr-secondary">
                <span className="line-shape line-shape_left">Portfolio</span>
              </h5>
              <h2>
                Our team has developed data{" "}
                <span className="clr-primary">initiatives </span>and{" "}
                <span className="clr-primary">digital </span> solutions.
              </h2>
            </div>
          </div>

          <PortfolioItemMap visible={2} showmore={false} />

          <div className="is-flex is-center mt-40">
            <Link
              to="/portfolio"
              className="btn btn-secondary   is-flex is-align-center col-gap-8 fw-500 is-uppercase"
              onClick={() => setActive("portfolio")}
            >
              <span>view all</span>
              <FaArrowRight />
            </Link>
          </div>
        </div>
      </section>
      <div className="trusted">
        <div className="container">
          <div className="title text-center mb-40">
            <div className="title-content title-content_max">
              <h5 className="clr-secondary ">
                <span className="line-shape">Our Partners</span>
              </h5>
            </div>
          </div>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={50}
            slidesPerView={2}
            loop={true}
            loopedSlides={1}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 640px
              480: {
                width: 480,
                slidesPerView: 3,
              },
              // when window width is >= 768px
              768: {
                width: 768,
                slidesPerView: 4,
              },
              1024: {
                width: 1024,
                slidesPerView: 5,
              },
            }}
          >
            {TrustedData.map((item, index) => {
              return (
                <SwiperSlide key={index} id={item.id}>
                  <div className="trusted-card">
                    <img src={item.image} alt={item.name} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Home;
