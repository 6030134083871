import React, { useState } from "react";
import { motion } from "framer-motion";
import PortfolioItemMap from "../PortfolioItemMap";
import HeroHexagon from "../HeroHexagon";

const Portfolio = () => {
  const [visible, setVisible] = useState(4);

  const showmoreButton = () => {
    setVisible((p) => p + 4);
  };

  const textAnimate = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  return (
    <>
      <div className="hero is-flex is-center  dir-column ">
        <div className="container">
          <div className="hero-content clr-white">
            <motion.div
              className="content"
              initial="hidden"
              whileInView={"visible"}
              viewport={{ once: false, amount: 0.1 }}
              transition={{ staggerChildren: 0.5 }}
            >
              <motion.h5 className="fw-600 " variants={textAnimate}>
                <span className="line-shape pb-12 line-shape_left">
                  key highlights
                </span>
              </motion.h5>

              <motion.h1 className="my-32 fs-h3" variants={textAnimate}>
                Our team has developed data{" "}
                <span className="italic">initiatives</span> and {""}
                <span className="italic">digital</span> {""}
                solutions.
              </motion.h1>
              <motion.p className="fw-400" variants={textAnimate}>
                Smooth <span className="italic"> Business</span> starts with
                good service {""}
                <span className="italic"> consultation</span>.
              </motion.p>
            </motion.div>
            <HeroHexagon />
          </div>
        </div>
      </div>
      <section className="highlight">
        <div className="container">
          <div className="title  mb-40">
            <div className="title-content ">
              <h5 className="clr-secondary">
                <span className="line-shape line-shape_left">Portfolios</span>
              </h5>
            </div>
          </div>
          <PortfolioItemMap
            visible={visible}
            showmoreButton={showmoreButton}
            showmore={true}
          />
        </div>
      </section>
    </>
  );
};

export default Portfolio;
