import HighlightImage from "../assets/images/portfolio.jpg";
import FluidWeb from "../assets/images/fluid-web.png";
import RecognizeWeb from "../assets/images/recognize-web.png";

import James from "../assets/images/avatar-female.jpg";
import Fluid from "../assets/images/fluid-logo.svg";
import Olumo from "../assets/images/olumo-logo.svg";
import Clayy from "../assets/images/clayy-logo.svg";
import Recognize from "../assets/images/recognize-logo.png";
import AccountStory from "../assets/images/as-logo.png";

import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaPhoneAlt,
  FaRegEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
const ProductData = [
  {
    id: 1,
    name: "menu",
    menuItems: [
      {
        id: 1,
        name: "home",
        to: "/",
      },
      {
        id: 2,
        name: "about",
        to: "/about",
      },
      {
        id: 3,
        name: "services",
        to: "/services",
      },
      {
        id: 4,
        name: "portfolio",
        to: "/portfolio",
      },
      // {
      //   id: 5,
      //   name: "teams",
      //   to: "/teams",
      // },
    ],
  },
  {
    id: 2,
    name: "services",
    serviceItems: [
      {
        id: 1,
        title: "Software & Apps Development",
        icon: "/",
        description:
          "DSL is an experienced software development firm providing offshore software development services. Our cost-effective solutions are built specifically for organizations by utilizing an intimate understanding of their business and industry landscape. We offer rich, user friendly and robust applications in combination with any modern database system. The application can be totally standalone running in a single machine, network solutions or internet connected application.",
      },
      {
        id: 2,
        title: "Software Application Testing",
        icon: "/",
        description:
          "DSL owe it to ourselves and to our clients to deliver the best application it can by providing Software Quality Assurance. We offer different types of software testing services from test case design to test report generation. DSL is experienced in Functional and nonfunctional testing. We maintain best practices in test planning, test case design, test environment creation, test case execution, bug reporting process, and finally test report generation. We have expertise in using different automated testing tools like Selenium, Cypress etc.",
      },
      {
        id: 3,
        title: "Technology Consultancy",
        icon: "/",
        description:
          "DSL provides high-end and mission critical enterprise solutions meeting all requirements to achieve ultimate business goal. We offer consultancy services that span the entire IT lifecycle, from business process reengineering to software system design, development and implementation. Our consultancy services are tailored to satisfy business requirements with the help of our individual and combined experience in design, implementation, and management of IT and communication systems around the world.",
      },
    ],
  },
  {
    id: 3,
    name: "highlight",
    highlightItems: [
      {
        id: 1,
        image: FluidWeb,
        title: "Fluid Digital marketing platform",
        description:
          "Fluid's mobile app gives your field the ability to summon leads, engage customers, and promote your brand from anywhere.",
        client: "fluid",
        startDate: "2019-01-01",
        endDate: "",
      },
      {
        id: 2,
        image: RecognizeWeb,
        title: "Recognize",
        description:
          "Recognize is an employee recognition & staff rewards program. Recognize team are inventors,technologists, & psychologists dedicated to building the best employee recognition app in the marketplace.",
        client: "Recognize",
        startDate: "2017-01-05",
        endDate: "",
      },
      {
        id: 3,
        image: HighlightImage,
        title: "Clayy ecommerce platform",
        description:
          "Kirus's mobile app gives your field the ability to summon leads, engage customers, and promote your brand from anywhere.",
        client: "Clayy",
        startDate: "2019-01-01",
        endDate: "",
      },
      {
        id: 4,
        image: HighlightImage,
        title: "Olumo Digital platform",
        description:
          "Olumo helps top performing companies collect employee data that inspires conversations and action.",
        client: "Olumo",
        startDate: "2019-01-01",
        endDate: "",
      },
    ],
  },
  {
    id: 4,
    name: "trusted",
    trustedItems: [
      {
        id: 1,
        name: "Fluid",
        image: Fluid,
      },
      {
        id: 2,
        name: "Olumo",
        image: Olumo,
      },
      {
        id: 3,
        name: "Recognize",
        image: Recognize,
      },
      {
        id: 4,
        name: "Clayy",
        image: Clayy,
      },
      {
        id: 5,
        name: "AccountStory",
        image: AccountStory,
      },

    ],
  },
  {
    id: 5,
    name: "footer",
    companyMenus: [
      {
        id: 1,
        name: "About",
        to: "/about",
      },
      // {
      //   id: 2,
      //   name: "Teams",
      //   to: "/teams",
      // },
      {
        id: 3,
        name: "Portfolio",
        to: "portfolio",
      },
    ],
  },
  {
    id: 6,
    name: "socialIcons",
    socialIcons: [
      {
        id: 1,
        icon: <FaFacebook />,
        url: "https://www.facebook.com/DanpheInfoTech",
      },
      // {
      //   id: 2,
      //   icon: <FaTwitter />,
      //   url: "https://www.facebook.com/DanpheInfoTech",
      // },
      {
        id: 3,
        icon: <FaLinkedin />,
        url: "https://www.linkedin.com/company/danphe-software-labs/",
      },
      // {
      //   id: 4,
      //   icon: <FaInstagram />,
      //   url: "https://www.linkedin.com/company/danphe-software-labs/",
      // },
    ],
  },
  {
    id: 7,
    name: "teams",
    teamsMember: [
      {
        id: 1,
        image: James,
        name: "Prem Prasad Khanal",
        post: "Sr.Frontend Developer",
        description:
          "I am a passionate Frontend Developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 2,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 3,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 4,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 5,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 6,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Railss developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 7,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 8,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 9,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 10,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 11,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
      {
        id: 12,
        image: James,
        name: "James watt",
        post: "Senior Rails Developer",
        description:
          "I am a passionate Rails developer who also relishes exploring new technology.",
        socialIcons: [
          {
            id: 1,
            icon: <FaFacebook />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 2,
            icon: <FaTwitter />,
            url: "https://www.facebook.com/DanpheInfoTech",
          },
          {
            id: 3,
            icon: <FaLinkedin />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
          {
            id: 4,
            icon: <FaInstagram />,
            url: "https://www.linkedin.com/company/danphe-software-labs/",
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: "contact",
    contactItems: [
      {
        id: 1,
        icon: <FaPhoneAlt />,
        name: "Phone Number",
        address: "+9779851182852",
      },
      {
        id: 2,
        icon: <FaRegEnvelope />,
        name: "Email Address",
        address: "info@danpheit.com",
      },
      {
        id: 3,
        icon: <FaMapMarkerAlt />,
        name: "Location",
        address: "Tanka Prasad Ghumti Sadak, Anamnagar, Kathmandu",
      },
    ],
  },
];
export default ProductData;
