import React from "react";
import { motion } from "framer-motion";
import HeroHexagon from "../HeroHexagon";

const About = () => {
  const textAnimate = {
    hidden: { opacity: 0, y: -100 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <>
      <div className="hero is-flex is-center  dir-column ">
        <div className="container">
          <div className="hero-content clr-white">
            <motion.div
              className="content"
              initial="hidden"
              whileInView={"visible"}
              viewport={{ once: false, amount: 0.1 }}
              transition={{ staggerChildren: 1 }}
            >
              <motion.h5 className="fw-600 " variants={textAnimate}>
                <span className="line-shape pb-12 line-shape_left">
                  Who we are
                </span>
              </motion.h5>

              <motion.h1 className="my-32 fs-h3" variants={textAnimate}>
                We are <span className="italic">Experienced </span> Problem Solvers
                and we have <span className="italic">profound knowledge</span> in web application
                development.
              </motion.h1>
              <motion.p className="fw-400" variants={textAnimate}>
                We deliver the work on the promised timeline.
              </motion.p>
            </motion.div>
            <HeroHexagon />
          </div>
        </div>
      </div>
      <section className="about">
        <div className="container">
          <div className="title text-center mb-40">
            <div className="title-content title-content_max">
              <h5 className="clr-secondary ">
                <span className="line-shape">About us</span>
              </h5>
              <h2>
                We have worked with <span className="clr-primary"> national and international </span> clients.
              </h2>
            </div>
          </div>
          <div className="about-content text-center">
            <p className="mb-32">
              Danphe Software Labs (DSL) is a Nepal based software service company that focuses on development and maintenance of the software and web application.
              DSL has a proven track record of building products for startups and businesses on growing stages.
              DSL focuses to empower our customers with customized business solutions through state of the art technology and best-of-breed business processes.
              DSL’s service portfolio encompasses the entire range of solutions required by the IT industry.
            </p>
            <p>
              Since establishment, we have build technical products and services which can be used by individuals, companies
              or organizations not only in local industries but also globally to better their day-to-day undertakings.
              We have more than four years of experience in developing technological solutions and delivering
              high-quality services.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
